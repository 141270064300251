import React, { useCallback, useEffect, useState } from "react";
import {
    Tab,
    TabList,
    MenuButton,
    Button,
    Menu,
    MenuTrigger,
    MenuList,
    MenuItem,
    MenuItemCheckbox,
    MenuCheckedValueChangeEvent,
    MenuCheckedValueChangeData,
    MenuGroup,
    MenuDivider,
    MenuGroupHeader,
    MenuPopover,
    SelectTabData,
    Input,
    Overflow,
    OverflowItem,
    useOverflowMenu,
    useIsOverflowItemVisible,
    makeStyles,
    shorthands,
    tokens,
    mergeClasses,
} from "@fluentui/react-components";
import { state } from "../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../videoExtendedViewLocalState";
import {
    LayerRegular,
    SearchRegular,
    MoreHorizontal20Filled,
} from "@fluentui/react-icons";
import "./videoExtendedViewCommandBar.css";
import { VideoExtendedViewTabKind } from "../../models";

const useStyles = makeStyles({
    root: {
        ...shorthands.borderBottom(
            tokens.strokeWidthThin,
            "solid",
            tokens.colorNeutralStroke2,
        ),
    },
    menuItemActive: {
        backgroundColor: tokens.colorNeutralBackground1Hover,
    },
});

export const insightsViewList = [
    { key: "faces", text: "People" },
    { key: "keywords", text: "Keywords" },
    { key: "labels", text: "Labels" },
    { key: "namedEntities", text: "Named entities" },
    { key: "emotions", text: "Emotions" },
    { key: "sentiments", text: "Sentiments" },
    { key: "shots", text: "Keyframes" },
    { key: "topics", text: "Topics" },
    { key: "scenes", text: "Scenes" },
    { key: "ocr", text: "OCR" },
    { key: "audioEffects", text: "Audio effects" },

    // cobra outcome
    { key: "themes", text: "Themes" },
    { key: "characters", text: "Characters" },
    { key: "actions", text: "Actions" },
];

export const timelineViewList = [
    { key: "text", text: "Transcript" },
    // cobra outcome
    { key: "summary", text: "Summary" },
];

export const viewList = [...insightsViewList, ...timelineViewList];

export const collectionViews = [
    { key: "all", text: "All", views: viewList.map(v => v.key) },
    {
        key: "storyboard",
        text: "Storyboard",
        views: ["faces", "labels", "shots"],
    },
    { key: "captioning", text: "Captioning", views: ["text", "summary"] },
    {
        key: "accessibility ",
        text: "Accessibility ",
        views: ["labels", "text", "ocr", "summary"],
    },
];

function VideoExtendedViewCommandBar(): JSX.Element {
    const classes = useStyles();
    const selectedTab = state.useState(videoExtendedLocalState.selectedTab);
    const query = state.useState(videoExtendedLocalState.query);
    const [debouncedQuery, setDebouncedQuery] = useState(query);

    useEffect(() => {
        const timer = setTimeout(
            () => videoExtendedLocalState.query.set(debouncedQuery),
            500,
        );
        return () => clearTimeout(timer);
    }, [debouncedQuery]);

    useEffect(() => {
        videoExtendedLocalState.query.set(query);
    }, [query]);

    return (
        <div
            className={mergeClasses("extended-view-command-bar", classes.root)}
        >
            <Overflow>
                <div className="extended-view-command-bar__overflow">
                    <OverflowItem id="tabs">
                        <TabList
                            selectedValue={selectedTab}
                            onTabSelect={(_e, d: SelectTabData): void => {
                                videoExtendedLocalState.selectedTab.set(
                                    d.value,
                                );
                            }}
                        >
                            <Tab
                                id={VideoExtendedViewTabKind.Insights.toString()}
                                value={VideoExtendedViewTabKind.Insights}
                            >
                                Insights
                            </Tab>
                            <Tab
                                id={VideoExtendedViewTabKind.Timeline.toString()}
                                value={VideoExtendedViewTabKind.Timeline}
                            >
                                Timeline
                            </Tab>
                        </TabList>
                    </OverflowItem>

                    <OverflowItem id="view">
                        <div>
                            <MenuListView isTriggerButton={true} />
                        </div>
                    </OverflowItem>

                    <OverflowMenu itemIds={["tabs", "view"]} />
                </div>
            </Overflow>
            <Input
                contentAfter={<SearchRegular />}
                value={debouncedQuery}
                onChange={(_e, data): void => setDebouncedQuery(data.value)}
                placeholder="Find"
                className="extended-view-command-bar__search"
            />
        </div>
    );
}

type MenuListViewProps = {
    isTriggerButton: boolean;
};

function MenuListView(props: MenuListViewProps): JSX.Element {
    const classes = useStyles();
    const selectedTab = state.useState(videoExtendedLocalState.selectedTab);
    const viewInsights = state.useState(videoExtendedLocalState.viewInsights);
    const viewTimeline = state.useState(videoExtendedLocalState.viewTimeline);
    const view =
        selectedTab === VideoExtendedViewTabKind.Insights
            ? Array.from(viewInsights.values())
            : Array.from(viewTimeline.values());

    const onChangeView = useCallback(
        (checkedItems: string[]): void => {
            if (selectedTab === VideoExtendedViewTabKind.Insights) {
                videoExtendedLocalState.viewInsights.set(new Set(checkedItems));
            }

            if (selectedTab === VideoExtendedViewTabKind.Timeline) {
                videoExtendedLocalState.viewTimeline.set(new Set(checkedItems));
            }
        },
        [selectedTab],
    );

    function onCheckedValueChange(
        _e: MenuCheckedValueChangeEvent,
        data: MenuCheckedValueChangeData,
    ): void {
        onChangeView(data.checkedItems);
    }

    return (
        <Menu
            checkedValues={{ view: view }}
            onCheckedValueChange={onCheckedValueChange}
            hasCheckmarks
        >
            <MenuTrigger disableButtonEnhancement>
                {props.isTriggerButton ? (
                    <MenuButton
                        appearance="subtle"
                        icon={<LayerRegular />}
                        className="extended-view-command-bar__view"
                    >
                        View
                    </MenuButton>
                ) : (
                    <MenuItem icon={<LayerRegular />}>View</MenuItem>
                )}
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    <MenuGroup>
                        <MenuGroupHeader>Show insights</MenuGroupHeader>
                        {collectionViews.map(i => (
                            <MenuItem
                                className={
                                    i.views.sort().toString() ===
                                    view.sort().toString()
                                        ? classes.menuItemActive
                                        : ""
                                }
                                key={i.key}
                                onClick={(): void => {
                                    onChangeView(i.views);
                                }}
                            >
                                {i.text}
                            </MenuItem>
                        ))}
                    </MenuGroup>
                    <MenuDivider />
                    <MenuGroup>
                        <MenuGroupHeader>Custom view</MenuGroupHeader>
                        {selectedTab === VideoExtendedViewTabKind.Insights
                            ? insightsViewList.map(i => (
                                  <MenuItemCheckbox
                                      name="view"
                                      value={i.key}
                                      key={i.key}
                                  >
                                      {i.text}
                                  </MenuItemCheckbox>
                              ))
                            : viewList.map(i => (
                                  <MenuItemCheckbox
                                      name="view"
                                      value={i.key}
                                      key={i.key}
                                  >
                                      {i.text}
                                  </MenuItemCheckbox>
                              ))}
                    </MenuGroup>
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}

type OverflowMenuItemProps = {
    id: string;
};

function OverflowMenuItem(props: OverflowMenuItemProps): JSX.Element | null {
    const isVisible = useIsOverflowItemVisible(props.id);
    const selectedTab = state.useState(videoExtendedLocalState.selectedTab);

    if (isVisible) {
        return null;
    }

    if (props.id === "tabs") {
        return (
            <>
                <MenuItem
                    onClick={(): void => {
                        videoExtendedLocalState.selectedTab.set(
                            VideoExtendedViewTabKind.Insights,
                        );
                    }}
                    className={
                        VideoExtendedViewTabKind.Insights === selectedTab
                            ? "extended-view-command-bar__menu-item_active"
                            : ""
                    }
                >
                    Insights
                </MenuItem>
                <MenuItem
                    onClick={(): void => {
                        videoExtendedLocalState.selectedTab.set(
                            VideoExtendedViewTabKind.Timeline,
                        );
                    }}
                    className={
                        VideoExtendedViewTabKind.Timeline === selectedTab
                            ? "extended-view-command-bar__menu-item_active"
                            : ""
                    }
                >
                    Timeline
                </MenuItem>
                <MenuDivider />
            </>
        );
    }

    if (props.id === "view") {
        return <MenuListView isTriggerButton={false} />;
    }

    return null;
}

type OverflowMenuProps = {
    itemIds: string[];
};

function OverflowMenu(props: OverflowMenuProps): JSX.Element | null {
    const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu hasIcons>
            <MenuTrigger disableButtonEnhancement>
                <Button
                    ref={ref}
                    icon={<MoreHorizontal20Filled />}
                    aria-label="More items"
                    appearance="subtle"
                />
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {props.itemIds.map(i => {
                        return <OverflowMenuItem key={i} id={i} />;
                    })}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
}

export default VideoExtendedViewCommandBar;
