import { createState } from "@persevie/statemanjs";
import { StorageItemPort } from "../../../../application/storageItemPort";
import { VideoExtendedViewTabKind } from "../models";

interface VideoExtendedLocalStatePort {
    thumbnails: StorageItemPort<{ [x: string]: string }>;
    selectedTab: StorageItemPort<unknown>;
    query: StorageItemPort<string>;
    viewInsights: StorageItemPort<Set<string>>;
    viewTimeline: StorageItemPort<Set<string>>;
}

const thumbnails = createState<{ [x: string]: string }>({});
const selectedTab = createState<unknown>(VideoExtendedViewTabKind.Insights);
const query = createState<string>("");
const viewInsights = createState<Set<string>>(
    new Set([
        "faces",
        "labels",
        "namedEntities",
        "emotions",
        "keyframes",
        "topics",
        "scenes",
        "text",
        "ocr",
    ]),
);
const viewTimeline = createState<Set<string>>(new Set(["text"]));

class VideoExtendedLocalStateAdapter implements VideoExtendedLocalStatePort {
    readonly thumbnails: StorageItemPort<{ [x: string]: string }> = thumbnails;
    readonly selectedTab: StorageItemPort<unknown> = selectedTab;
    readonly query: StorageItemPort<string> = query;
    readonly viewInsights: StorageItemPort<Set<string>> = viewInsights;
    readonly viewTimeline: StorageItemPort<Set<string>> = viewTimeline;
}

export const videoExtendedLocalState = new VideoExtendedLocalStateAdapter();
