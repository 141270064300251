import React, { RefObject } from "react";

import ExtendedViewTags from "./ExtendedViewTags/ExtendedViewTags";
import ExtendedViewGroupTags from "./ExtendedViewGroupTags/ExtendedViewGroupTags";
import ExtendedViewPeople from "./ExtendedViewPeople/ExtendedViewPeople";
import Emotions from "./Emotions/Emotions";
import ExtendedViewTopics from "./ExtendedViewTopics/ExtendedViewTopics";
import { Subtitle1, Text } from "@fluentui/react-components";
import { Insights } from "../../../../../application/cognitiveMetadata/cognitiveMetadataPort";
import { ExtendedViewData } from "../../models";
import { state } from "../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../videoExtendedViewLocalState";

type InsightsProps = {
    data: Insights;
    duration: number;
    videoRef: RefObject<HTMLVideoElement>;
};

function InsightsTab(props: InsightsProps): JSX.Element | null {
    const viewInsights = state.useState(videoExtendedLocalState.viewInsights);

    if (props.videoRef === null || props.data === null) {
        return <Subtitle1>No insights found for this video</Subtitle1>;
    }

    const countElementsToShow = [...viewInsights.values()].reduce(
        (acc: number, i) => {
            if (i === "scenes" || i == "text" || i === "ocr") {
                return acc;
            }
            const insight = props.data[i as keyof Insights];

            if (insight) {
                return acc + insight.length;
            } else {
                if (i === "namedEntities") {
                    const brands = props.data.brands;
                    const namedLocations = props.data.namedLocations;
                    const namedPeople = props.data.namedPeople;

                    if (brands !== null) {
                        acc += brands.length;
                    }

                    if (namedLocations !== null) {
                        acc += namedLocations.length;
                    }

                    if (namedPeople !== null) {
                        acc += namedPeople.length;
                    }
                }
                if (i === "topics") {
                    const topicsIab = props.data.topicsIab;
                    const topicsIptc = props.data.topicsIptc;

                    if (topicsIab !== null) {
                        acc += topicsIab.length;
                    }

                    if (topicsIptc !== null) {
                        acc += topicsIptc.length;
                    }
                }
            }

            return acc;
        },
        0,
    );

    if (countElementsToShow === 0) {
        return (
            <Text className="extended-view__empty">
                No insight found or video excluded for AI processing due to its
                length. Please contact you system admin for more details.
            </Text>
        );
    }

    return (
        <div className="extended-view__insights">
            {viewInsights.has("faces") && props.data.faces ? (
                <ExtendedViewPeople
                    title="People"
                    data={props.data.faces}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("keywords") && props.data.keywords ? (
                <ExtendedViewTags
                    title="Keywords"
                    data={props.data.keywords}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("labels") ? (
                <ExtendedViewTags
                    title="Labels"
                    data={props.data.labels}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("namedEntities") ? (
                <ExtendedViewGroupTags
                    title="Named entities"
                    groups={[
                        {
                            title: "People",
                            data: props.data.namedPeople ?? [],
                            countOverflow: 0,
                            isOverflow: true,
                        },
                        {
                            title: "Locations",
                            data: props.data.namedLocations ?? [],
                            countOverflow: 0,
                            isOverflow: true,
                        },
                        {
                            title: "Brands",
                            data: props.data.brands ?? [],
                            countOverflow: 0,
                            isOverflow: true,
                        },
                    ]}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("emotions") && props.data.emotions ? (
                <Emotions
                    title="Emotions"
                    data={props.data.emotions}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("sentiments") && props.data.sentiments ? (
                <Emotions
                    title="Sentiments"
                    data={props.data.sentiments.filter(
                        (i: ExtendedViewData) => i.value !== "Neutral",
                    )}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("topics") &&
            props.data.topicsIab &&
            props.data.topicsIptc ? (
                <ExtendedViewTopics
                    title="Topics"
                    iab={props.data.topicsIab}
                    iptc={props.data.topicsIptc}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("audioEffects") && props.data.audioEffects ? (
                <ExtendedViewTags
                    title="Audio effects"
                    data={props.data.audioEffects}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("themes") && props.data.themes ? (
                <ExtendedViewTags
                    title="Themes"
                    data={props.data.themes}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("characters") && props.data.characters ? (
                <ExtendedViewTags
                    title="Characters"
                    data={props.data.characters}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
            {viewInsights.has("actions") && props.data.actions ? (
                <ExtendedViewTags
                    title="Actions"
                    data={props.data.actions}
                    videoRef={props.videoRef}
                    duration={props.duration}
                />
            ) : null}
        </div>
    );
}

export default InsightsTab;
