import React, { RefObject, useEffect, useMemo, useRef, useState } from "react";
import { ViewportList, ViewportListRef } from "react-viewport-list";
import {
    Text,
    Link,
    Body1Stronger,
    makeStyles,
    shorthands,
    tokens,
    mergeClasses,
} from "@fluentui/react-components";
import {
    getSeconds,
    formatSeconds,
    onJumpToTime,
    useCurrentTime,
} from "../../utils";
import TimelineKeyFrames from "./TimelineKeyframes/TimelineKeyframes";
import TimelineFaces from "./TimelineFaces/TimelineFaces";
import TimelineLabel from "./TimelineLabel/TimelineLabel";
import TimelineEmotions from "./TimelineEmotions/TimelineEmotions";
import TimelineText from "./TimelineText/TimelineText";
import "./timeline.css";
import {
    AppearanceInsight,
    Insights,
} from "../../../../../application/cognitiveMetadata/cognitiveMetadataPort";
import { ExtendedViewData } from "../../models";
import { state } from "../../../../state/stateAdapter";
import { videoExtendedLocalState } from "../videoExtendedViewLocalState";

export const useTimelineStyles = makeStyles({
    row: {
        ...shorthands.transition(
            "background, border, color",
            tokens.durationFaster,
            tokens.curveEasyEase,
        ),
        "&:hover": {
            backgroundColor: tokens.colorNeutralBackground1Hover,
        },
    },
    rowActive: {
        backgroundColor: tokens.colorBrandBackground2,
    },
    time: {
        color: tokens.colorNeutralForeground1,
        backgroundColor: tokens.colorNeutralBackground5,
    },
    text: {
        fontSize: "13px",
        lineHeight: "16px",
    },
});

type TimelineProps = {
    data: Insights;
    duration: number;
    videoRef: RefObject<HTMLVideoElement>;
};

export type TimelineDataItem = {
    value: string;
    image?: string;
    startTime: string;
    endTime: string;
};

type TimelineData = {
    startTime: number;
    timeCode: string;
    group: Map<string, TimelineDataItem[]>;
};

function addItemInfo(
    acc: Map<string, TimelineData>,
    item: ExtendedViewData,
    component: string,
): Map<string, TimelineData> {
    item.appearances.forEach((a: AppearanceInsight) => {
        const startTime = getSeconds(a.startTime);
        const timeCode = formatSeconds(parseFloat(startTime.toFixed(0)));
        const dataItem = {
            value: item.value,
            image: item.image,
            startTime: item.appearances[0].startTime,
            endTime: item.appearances[0].endTime,
        };

        if (acc.has(timeCode)) {
            if (acc.get(timeCode)?.group) {
                if (acc.get(timeCode)?.group.get(component)) {
                    const existData: TimelineDataItem[] =
                        acc.get(timeCode)?.group.get(component) ?? [];

                    const items = [
                        ...new Map(
                            [...existData, dataItem].map(i => [i.value, i]),
                        ).values(),
                    ].sort((a, b) => {
                        const nameA = a.value.toUpperCase();
                        const nameB = b.value.toUpperCase();

                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }

                        return 0;
                    });

                    acc.get(timeCode)?.group.set(component, items);
                } else {
                    acc.get(timeCode)?.group.set(component, [dataItem]);
                }
            }
        } else {
            const group = new Map();

            group.set(component, [dataItem]);

            acc.set(timeCode, {
                timeCode,
                startTime,
                group,
            });
        }
    });

    return acc;
}

function getHightlights(
    data: Insights,
    view: Set<string>,
    query: string,
): string[] {
    const hightLights = new Set<string>();

    if (view.has("namedEntities")) {
        data.namedLocations !== null &&
            data.namedLocations.forEach(i => hightLights.add(i.value));
        data.brands !== null &&
            data.brands.forEach(i => hightLights.add(i.value));
        data.namedPeople !== null &&
            data.namedPeople.forEach(i => hightLights.add(i.value));
    }

    if (view.has("keywords")) {
        data.keywords !== null &&
            data.keywords.forEach(i => hightLights.add(i.value));
    }

    if (query.length > 0) {
        hightLights.add(query);

        return Array.from(hightLights.values()).filter(
            a => a.toLowerCase().search(query.toLowerCase()) !== -1,
        );
    }

    return Array.from(hightLights.values());
}

function Timeline(props: TimelineProps): JSX.Element | null {
    const classes = useTimelineStyles();
    const view = state.useState(videoExtendedLocalState.viewTimeline);
    const query = state.useState(videoExtendedLocalState.query);
    const ref = useRef(null);
    const listRef = useRef<ViewportListRef>(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isAutoScroll, setIsAutoScroll] = useState(true);
    const currentTime = useCurrentTime(props.videoRef, true);
    const sortedData = useMemo(() => {
        const timeline = Object.keys(props.data).reduce((acc, insightKey) => {
            const item = props.data[insightKey as keyof Insights];

            if (item !== null && item !== undefined) {
                item.forEach((item: ExtendedViewData) => {
                    switch (insightKey) {
                        case "namedPeople":
                        case "namedLocations":
                        case "brands":
                        case "keywords":
                            return acc;
                        case "sentiments":
                            if (item.value === "Neutral") {
                                return acc;
                            } else if (item.appearances) {
                                return addItemInfo(acc, item, insightKey);
                            }
                            break;
                        case "shots":
                        case "scenes":
                            if (item.keyFrames) {
                                item.keyFrames.forEach(k => {
                                    acc = addItemInfo(acc, k, "keyframes");
                                });
                            }
                            return acc;
                        default:
                            return addItemInfo(acc, item, insightKey);
                    }
                });
            }

            return acc;
        }, new Map<string, TimelineData>());

        const sortedData = [...timeline.values()].sort((a, b) => {
            return a.startTime - b.startTime;
        });

        return sortedData;
    }, [props.data]);
    const hightLights: string[] = getHightlights(props.data, view, query);
    const data = sortedData.reduce((icc: TimelineData[], i: TimelineData) => {
        const group = [...i.group.entries()];
        const searchValues = new Map<string, TimelineDataItem[]>(
            group.reduce(
                (
                    acc: [string, TimelineDataItem[]][],
                    a: [string, TimelineDataItem[]],
                ) => {
                    if (!view.has(a[0])) {
                        return acc;
                    }

                    a[1] = a[1].filter(
                        b => b.value.toLowerCase().search(query) !== -1,
                    );

                    if (a[1].length > 0) {
                        acc.push(a);
                    }

                    return acc;
                },
                [],
            ),
        );

        if (searchValues.size) {
            i.group = searchValues;
            return icc.concat(i);
        }

        return icc;
    }, []);

    useEffect(() => {
        let closetsIndex = data.findLastIndex(
            (d: TimelineData) => d.startTime <= currentTime,
        );

        if (closetsIndex === -1) {
            closetsIndex = 0;
        }

        if (closetsIndex !== activeIndex) {
            if (listRef.current !== null && isAutoScroll) {
                listRef.current.scrollToIndex({
                    index: activeIndex,
                });
            }
            setActiveIndex(closetsIndex);
        }
    }, [activeIndex, currentTime, data, listRef, isAutoScroll]);

    if (props.videoRef === null || props.data === null) {
        return <Text className="extended-view__empty">No insights found</Text>;
    }

    if (data.length === 0 && query.length > 0) {
        return (
            <Text className="extended-view__empty">
                No insights found for <Body1Stronger>{query}</Body1Stronger>
            </Text>
        );
    }

    if (data.length === 0) {
        return (
            <Text className="extended-view__empty">
                No insight found or video excluded for AI processing due to its
                length. Please contact you system admin for more details.
            </Text>
        );
    }

    return (
        <div className="timeline">
            <div className="timeline__head">
                <Link
                    as="button"
                    className="timeline__btn"
                    onClick={(): void => {
                        setIsAutoScroll(!isAutoScroll);
                    }}
                >
                    Scroll follows playback is {isAutoScroll ? "on" : "off"}
                </Link>
            </div>
            <div
                className="timeline__list"
                ref={ref}
                onWheel={(): void => {
                    setIsAutoScroll(false);
                }}
            >
                <ViewportList ref={listRef} viewportRef={ref} items={data}>
                    {(item: TimelineData, index): JSX.Element => {
                        return (
                            <div
                                className={mergeClasses(
                                    classes.row,
                                    "timeline__row",
                                    index === activeIndex
                                        ? classes.rowActive
                                        : "",
                                )}
                                key={item.startTime}
                                role="presentation"
                                onClick={(e): void => {
                                    onJumpToTime(
                                        e,
                                        item.startTime,
                                        props.videoRef,
                                    );
                                }}
                            >
                                <div
                                    className={mergeClasses(
                                        "timeline__time",
                                        classes.time,
                                    )}
                                >
                                    {item.timeCode}
                                </div>
                                <div className="timeline__group">
                                    <TimelineKeyFrames
                                        data={item.group.get("keyframes")}
                                    />
                                    <TimelineFaces
                                        data={item.group.get("faces")}
                                    />
                                    <TimelineText
                                        data={item.group.get("ocr")}
                                        italic={true}
                                        hightLights={hightLights}
                                    />
                                    <TimelineText
                                        data={item.group.get("text")}
                                        italic={false}
                                        hightLights={hightLights}
                                    />
                                    <TimelineLabel
                                        data={item.group.get("labels")}
                                    />
                                    <TimelineEmotions
                                        data={item.group.get("emotions")}
                                    />
                                    <TimelineEmotions
                                        data={item.group.get("sentiments")}
                                    />
                                    <TimelineLabel
                                        data={item.group.get("topicsIptc")}
                                    />
                                    <TimelineLabel
                                        data={item.group.get("topicsIab")}
                                    />
                                    <TimelineLabel
                                        data={item.group.get("audioEffects")}
                                    />
                                    <TimelineText
                                        data={item.group.get("summary")}
                                        italic={false}
                                        hightLights={hightLights}
                                    />
                                    <TimelineLabel
                                        data={item.group.get("themes")}
                                    />
                                    <TimelineLabel
                                        data={item.group.get("characters")}
                                    />
                                    <TimelineLabel
                                        data={item.group.get("actions")}
                                    />
                                </div>
                            </div>
                        );
                    }}
                </ViewportList>
            </div>
        </div>
    );
}

export default Timeline;
