import React, { useCallback, useRef, useState } from "react";
import {
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogTitle,
    DialogBody,
    DialogContent,
    Button,
    Body1Stronger,
    Caption1,
    makeStyles,
    shorthands,
    tokens,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import Scenes from "./Insights/Scenes/Scenes";
import InsightsTab from "./Insights/InsightsTab";
import Timeline from "./Timeline/Timeline";
import "./videoExtendedView.css";
import { AirFileDetails } from "../../../../domain/airFile/details";
import { state } from "../../../state/stateAdapter";

import VideoExtendedViewCommandBar from "./VideoExtendedViewCommandBar/VideoExtendedViewCommandBar";
import { VideoExtendedViewTabKind } from "../models";
import { videoExtendedLocalState } from "./videoExtendedViewLocalState";
import { CognitiveMetadata } from "../../../../application/cognitiveMetadata/cognitiveMetadataPort";

type VideoExtendedViewProps = {
    airFileDetails: AirFileDetails;
    isOpen: boolean;
    cognitiveMetadata: CognitiveMetadata;
    onClose: () => void;
};

export const useViewExtendedStyles = makeStyles({
    surface: {
        height: "95vh",
        width: "95vw",
        maxWidth: "95vw",
        paddingRight: 0,
    },
    body: {
        height: "100%",
    },
    closeBtn: {
        marginRight: "18px",
    },
    title: {
        "@media screen and (max-width: 768px)": {
            rowGap: "16px",
            fontSize: tokens.fontSizeBase300,
            lineHeight: tokens.lineHeightBase300,
        },
    },
    link: {
        fontSize: "12px",
        lineHeight: "14px",
        fontWeight: 400,
        minWidth: "50px",
        minHeight: "22px",
        ...shorthands.borderWidth(0),
        ...shorthands.padding("1px", "8px", "1px", "0"),
        width: "1fr",
        "&:hover": {
            color: tokens.colorBrandForegroundLink,
            textDecorationLine: "underline",
        },
    },
    chevron: {
        paddingRight: 0,
    },
});

function VideoExtendedView(props: VideoExtendedViewProps): JSX.Element | null {
    const videoRef = useRef<HTMLVideoElement>(null);
    const classesViewExtended = useViewExtendedStyles();
    const selectedTab = state.useState(videoExtendedLocalState.selectedTab);
    const [duration, setDuration] = useState<number>(0);

    const videoSrc =
        props.airFileDetails.mediaPreview!.previewUrl ??
        props.airFileDetails.mediaPreview!.thumbnailUrl;

    const handleDuration = useCallback((): void => {
        if (videoRef.current !== null) {
            setDuration(videoRef.current.duration);
        }
    }, []);

    return (
        <Dialog
            open={props.isOpen}
            onOpenChange={(_e, data): void => {
                if (!data.open) {
                    props.onClose();
                }
            }}
        >
            <DialogSurface className={classesViewExtended.surface}>
                <DialogBody className={classesViewExtended.body}>
                    <DialogTitle
                        as="div"
                        action={
                            <DialogTrigger action="close">
                                <Button
                                    className={classesViewExtended.closeBtn}
                                    appearance="subtle"
                                    aria-label="close"
                                    icon={<Dismiss24Regular />}
                                />
                            </DialogTrigger>
                        }
                    >
                        <Body1Stronger block>
                            {props.airFileDetails.title}
                        </Body1Stronger>
                        <Caption1 block>Extended view</Caption1>
                    </DialogTitle>

                    <DialogContent>
                        <div className="extended-view">
                            {props.airFileDetails.mediaPreview!.thumbnailUrl &&
                            videoSrc ? (
                                <div className="extended-view__video">
                                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                                    <video
                                        controls
                                        className="extended-view__player"
                                        poster={
                                            props.airFileDetails.mediaPreview!
                                                .thumbnailUrl
                                        }
                                        onDurationChange={handleDuration}
                                        ref={videoRef}
                                        src={videoSrc}
                                    />
                                </div>
                            ) : null}
                            <div className="extended-view__scenes">
                                {videoSrc !== undefined && videoSrc !== null ? (
                                    <Scenes
                                        src={videoSrc}
                                        scenes={
                                            props.cognitiveMetadata.insights
                                                .scenes
                                        }
                                        shots={
                                            props.cognitiveMetadata.insights
                                                .shots
                                        }
                                        videoRef={videoRef}
                                    />
                                ) : null}
                            </div>
                            <div className="extended-view__info">
                                <>
                                    <VideoExtendedViewCommandBar />

                                    {selectedTab ===
                                        VideoExtendedViewTabKind.Insights && (
                                        <InsightsTab
                                            data={
                                                props.cognitiveMetadata.insights
                                            }
                                            videoRef={videoRef}
                                            duration={duration}
                                        />
                                    )}

                                    {selectedTab ===
                                        VideoExtendedViewTabKind.Timeline && (
                                        <Timeline
                                            data={
                                                props.cognitiveMetadata.insights
                                            }
                                            videoRef={videoRef}
                                            duration={duration}
                                        />
                                    )}
                                </>
                            </div>
                        </div>
                    </DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
}

export default VideoExtendedView;
