import { NotificationService } from "../notification/notificationService";
import { StoragePort } from "../ports";
import {
    CognitiveMetadata,
    CognitiveMetadataFetchParams,
    CognitiveMetadataPort,
} from "./cognitiveMetadataPort";
import { AirErrorService } from "../airError/airErrorService";
import { AirErrorKind } from "../airError/airError";
import { Option } from "../../domain/types/types";

export class CognitiveMetadataService {
    private readonly _cognitiveMetadataPort: CognitiveMetadataPort;
    private readonly _errorService: AirErrorService;
    private readonly _storagePort: StoragePort;
    private readonly _notificationService: NotificationService;

    constructor(
        cognitiveMetadataPort: CognitiveMetadataPort,
        errorService: AirErrorService,
        storagePort: StoragePort,
        notificationService: NotificationService,
    ) {
        this._cognitiveMetadataPort = cognitiveMetadataPort;
        this._errorService = errorService;
        this._storagePort = storagePort;
        this._notificationService = notificationService;
    }

    async fetch(
        params: CognitiveMetadataFetchParams,
    ): Promise<Option<CognitiveMetadata>> {
        try {
            this._errorService.clear(AirErrorKind.FetchCognitiveMetadata);
            this._storagePort.cognitiveMetadata.isQueryInProgress.set(true);

            const metadata = await this._cognitiveMetadataPort.fetch(params);

            this._storagePort.cognitiveMetadata.metadata.set(metadata);
            this._storagePort.cognitiveMetadata.isQueryInProgress.set(false);

            return this._storagePort.cognitiveMetadata.metadata.get();
        } catch (error) {
            this._storagePort.cognitiveMetadata.isQueryInProgress.set(false);

            if ((error as Error).message.slice(0, 3) == "404") {
                return undefined;
            }

            const airError = this._errorService.createAirError(
                AirErrorKind.FetchCognitiveMetadata,
                error as Error,
            );
            this._notificationService.show(airError.toString(), "error");
            this._errorService.save(airError);
        }
    }
}
