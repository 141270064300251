import { AppearanceInsight } from "../../../application/cognitiveMetadata/cognitiveMetadataPort";
import { RefObject } from "react";

export enum VideoExtendedViewTabKind {
    Insights,
    Timeline,
}

export type ExtendedViewData = {
    value: string;
    description: string | null;
    appearances: AppearanceInsight[];
    image?: string;
    title?: string;
    url?: string;
    keyFrames?: {
        value: string;
        description: string | null;
        appearances: AppearanceInsight[];
    }[];
};

export type ExtendedViewSectionParams = {
    duration: number;
    title: string;
    videoRef: RefObject<HTMLVideoElement>;
    data: ExtendedViewData[];
};
